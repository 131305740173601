.input-group {
  flex-direction: column;
  align-content: center;
  width: 100%;
  max-width: calc(100vw - 50px);
  padding: 0 1.25rem;
  margin: 0 auto;
}
input,
.dropdown {
  max-width: 550px;
  margin: .5rem 1rem !important;
}
.dropdown button {
  width: 100%;
}
label {
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 5px;
  padding-top: 10px;
}

.attached, .attached input {
  margin-bottom: 0 !important;
}

.filename {
  font-weight: 700;
  font-size: 1.2em;
  margin: 1.2rem auto;
  margin-bottom: .5rem;
}

.preview {
  margin: 1.2rem auto;
}

.error {
  color: red;
}

.spin {
  animation: spin 3s infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}


/* suggestions */
.react-autosuggest__container > input {
  width: 100%;
}
.react-autosuggest__suggestions-list {
  list-style: none;
}
.suggestions {
  font-style: italic;
  width: 100%;
  background-color: #ffe3e8;
  margin: 5px auto;
}
.suggestions:active, .suggestions:hover {
  background-color: #ff4768;
  color: white;
}